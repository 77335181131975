.full-width {
  width: 100% !important;
}

.hidden {
  display: none !important;
}

.shown {
  display: block !important;
}

.mt-2rem {
  margin-top: 2rem !important;
}
