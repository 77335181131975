@import 'components/mixins.scss';

.ql-toolbar.ql-snow {
  border: 1px solid #e4e9f0 !important;
}

.ql-container.ql-snow {
  border: 1px solid #e4e9f0 !important;
}

.ql-editor.ql-blank::before {
  color: #595c97 !important;
}
